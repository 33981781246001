.bytemd {
  height: 400px;
  border: 1px solid #ccccd9;
  font-size: 16px;
  position: relative;
  box-shadow: 0 1px 4px rgb(0 0 0/8%);
  transition: box-shadow 0.2s ease;
  border-radius: 4px;
}

.bytemd-toolbar {
  background-color: #f8f9fd;
  border-radius: 4px 4px 0 0;
  border-bottom: 1px solid #cdcde0;
  padding: 8px;
}

.bytemd-body {
  height: calc(100% - 84px);
}

.bytemd-status {
  padding: 8px;
}

.markdown-body {
  line-height: 1.5em;

  p {
    margin: 5px 0;
  }

  ul,
  ol {
    padding-left: 20px;
  }

  td,
  th {
    border: 1px solid #ccccd9;
    padding: 5px;
  }
}
